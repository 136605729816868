.Quotation_table {
    border-collapse: collapse;
    width: 100%;
}

.Quotation_table th, 
.Quotation_table td {
    border-left: 1px solid #D9D9D9;
    border-right: 1px solid #D9D9D9; 
    padding: 2px 12px;
    text-align: start;
    max-width: 176px;
}


.Quotation_table th:last-child, 
.Quotation_table td:last-child {
    text-align: end !important;
    height: auto !important;
}

.Quotation_table td .ant-btn {
    padding: 0 !important;
}
.Quotation_table td {
    background-color: #FAFAFA;
}

.Quotation_table th {
    font-size: 14px;
    font-weight: 400;
    color: #595959;
    border-bottom: 1px solid #D9D9D9;
    border-top: 1px solid #D9D9D9;
    border-left: none; 
    border-right: none; 
}

.Quotation_table th:first-child {
    border-left: 1px solid #D9D9D9; 
}

.Quotation_table th:last-child {
    border-right: 1px solid #D9D9D9;
}

.Quotation_table tr:last-child td {
    border-bottom: 1px solid #D9D9D9; 
}

.Quotation_table .ant-btn-link {
    color: #4800FF;
}

.Modal_qt {
    max-width: 907px !important;
    min-width: 907px !important;
}

.Modal_qt .ant-modal-header,
.Modal_ht .ant-modal-header {
    margin-bottom: 0 !important;
}

.Modal_ht {
    min-width: 768px;
    max-width: 768px;
}

.Quotation_firstContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Quotation_subContainer {
    display: flex;
    gap: 40px;
}

.Quotation_subContainer span {
    color: #595959;
}

.Quotation_subContainer span span {
    color: #000000;
}

.Quotation_upload,
.Quotation_upload .anticon svg {
    /* color: #4800FF !important; */
    cursor: pointer;
}


.Quotation_section {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.Quotation_subtitle {
    display: flex;
    justify-content: space-between;
}

.Quotation_subtitle span:first-child {
    color: #595959;
    font-weight: 700;
}

.Quotation_subtitle span:nth-child(2) {
    color: #4800FF;
    cursor: pointer;
}

.Quotation_eachFormRow {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
    flex-wrap: wrap;
}
/* .active-input {
    border: 1px solid #4800FF;
} */

.Quotation_eachForm {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    width: 199px;
}

.Quotation_eachForm .ant-input,
.Quotation_eachForm .ant-picker,
.Quotation_eachForm .ant-input-number{
    width: 100%;
    padding: 8px 12px;
    border-radius: 3px;
    border: 1px solid #D9D9D9;
}

.Quotation_eachForm .ant-input-number-input {
    height: 22px;
    padding: 0;
}


.Quotation_eachAttachmentRow {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    flex-wrap: wrap;
}

.Quotation_eachAttachment {
    display: flex;
    padding: 8px 8px 8px 12px;
    align-items: center;
    justify-content: space-between;
    width: 188.76px;
    border-radius: 4px;
    background: #FAFAFA;
}

.Quotation_eAr {
    display: flex;
    align-items: center;
    gap: 8px;
}

.Quotation_eAr .ant-avatar {
    border: 0.375px solid #4800FF !important;
    background: #F0EBFF !important;
}

.Quotation_eAr .anticon svg {
    color: #4800FF !important;
    transform: translateY(1px);
}

.Quotation_eAn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.Quotation_eAn span:nth-child(2) {
    color: #BFBFBF;
    font-size: 12px;
    line-height: 20px;
}

.Quotation_eAn span span:nth-child(2) {
    font-size: 12px;
    color: #000000;
}

.Quotation_section .ant-typography {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: #FAFAFA;
    border: none;
    border-radius: 0;
    max-height: 167px;
    overflow-y: auto;
}

.Quotation_typoTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Quotation_typoTitle span {
    color: #8C8C8C;
    font-size: 12px;
}

.Quotation_message {
    gap: 4px;
    display: flex;
    flex-direction: column;
}

/* scroll bar CSS */
.Quotation_section .ant-typography::-webkit-scrollbar {
    width: 8px;
}

.Quotation_section .ant-typography::-webkit-scrollbar-thumb {
    background-color:#8C8C8C; 
    border-radius: 6px; 
}

.Quotation_section .ant-typography::-webkit-scrollbar-thumb:hover {
    background-color: #555; 
}

.Quotation_section textarea {
    padding: 5px 12px;
    border-radius: 2px;
    border: 1px solid #D9D9D9;
}

.Quotation_inputFooter {
    display: flex;
    justify-content: space-between;
}

.Quotation_inputFeature {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
}

.Quotation_inputFeature i {
    display: flex;
    align-items: center;
    gap: 8px;
}

.Quotation_inputFeature i hr {
    height: 12px;
    color: rgba(0, 0, 0, 0.45);
}

.Quotation_inputFeature .anticon svg {
    height: 14px;
    width: 14px;
    color: rgba(0, 0, 0, 0.45);
}

.Quotation_inputFeature .anticon svg:hover {
    cursor: pointer;
    color: #000000;
}

.Quotation_inputFeature .ant-btn {
    display: flex;
    padding: 1px 8px 1px 6px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: #4800FF;
    color: #FFF;
    font-size: 13px;
    line-height: 20px;
}

.Quotation_inputFeature .ant-upload-wrapper .ant-upload-list.ant-upload-list-picture .ant-upload-list-item,
.Quotation_inputFeature .ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item {
    height: 46px;
    max-width: 236px;
    margin: 0;
    position: absolute;
    bottom: 39px;
    left: 30px;
    border-radius: 4px;
}

.Quotation_inputFeature .ant-upload-list-item-progress {
    bottom: 4px !important;
    width: 90% !important;
} 

.Quotation_inputFeature .ant-upload-wrapper .ant-btn {
    background-color: transparent;
}

.Quotation_inputFeature .ant-upload-wrapper .ant-upload-list-item-thumbnail .anticon svg {
    height: 30px;
    width: 30px;
}

.Quotes_footer .ant-btn .anticon svg {
    height: 14px;
    width: 14px;
}
.Quotes_footer {
    display: flex;
    gap: 20px;
}

.Approval_footer {
    display: flex;
    gap: 20px;
}

.Approval_footer .ant-btn:first-child {
    border: 1px solid #52C41A;
    background: #52C41A;
    color: #FFFFFF;
}

.Approval_footer .ant-btn:last-child {
    border: 1px solid #FF4D4F;
    background: #FF4D4F;
    color: #FFFFFF;
}

.Approvals_ilink,
.Approvals_ilink .anticon svg {
    color: #4800FF !important;
    cursor: pointer;
}

.Approvals_ilink .anticon svg {
    height: 14px !important;
    width: 14px !important;
    transform: scaleX(-1);
}

.add-note-btn.disabled {
    border: 1px solid #D9D9D9;
    background: #F5F5F5;
    color: rgba(0, 0, 0, 0.25) !important;
}

.quoteRemove.disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }

.Quotation_nofile {
    display: flex;
    width: 209px;
    padding: 18px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background:#FAFAFA;
    color: rgba(0, 0, 0, 0.25);

}

.Quotation_history {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
}

.Quotation_subHistory {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    width: 100%;
}

.Quotation_subHistoryDate {
    color:  #8C8C8C;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    min-width: 138px;
}

.Quotation_contantH {
    display: flex;
    align-items: center;
    gap: 12px;
}

.Quotation_contantH .ant-avatar {
    color: #FA541C;
    font-size: 16px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Quotation_contantH p {
    color: #595959;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin: 0;
    max-width: 504px;
}

.seeFormButton {
    gap: 0 !important;
}

.seeFormButton .anticon svg{
    transform: translateY(2px);
}

.Attachment_access span{
    color: #FFFFFF !important;
}
/* -------------------------------------------------------------------------------------------------------------------- */

.ListComponent_radio .ant-radio-wrapper {
    color: #595959;
}
.ListComponent_radioOption {
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;
}

.ListComponent_radioOption p {
    color: #4800FF;
}

.ListComponent_radio .ant-radio-wrapper .ant-radio-checked .ant-radio-inner,
.ListComponent_box1 .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: #4800FF;
    background-color: #4800FF;
}
.quoteRemove {
    cursor: pointer;
    color: #4800FF;
}
.Quotation_eachAttachment a {
    color: inherit;
    text-decoration: none;
  }