.Et_container {
  display: flex;
  margin: 0 auto;
  position: relative;
  top: 86px;
  width: 96vw;
}

.Et_subContainer {
  display: flex;
  margin: 0 auto;
  align-items: center;
}
.Et_subContainer img {
  width: 49.5%;
}

.Et_textContainer {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
}

.Et_textContainer .ant-btn {
  padding: 6px 30px;
  border-radius: 8px;
  border: 1px solid #4800ff;
  background: #4800ff;
  color: #fff;
  font-size: 20px;
  height: 44px;
  font-weight: 500;
  line-height: 28px;
}

.Et_text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 26px;
}

.Et_text h1 {
  color: #262626;
  font-size: 52px;
  font-weight: 500;
  line-height: 46px;
  text-align: start;
  margin: 0;
}

.Et_text span {
  color: #434343;
  font-size: 22px;
  font-weight: 400;
  line-height: 36px;
}
.Et_text span a {
  color: #4800ff;
  text-decoration: none;
}

.Et_subText {
  max-width: 573px;
  text-align: start;
}

@media (max-width: 1082px) {
  .Et_text h1 {
    font-size: 38px;
  }

  .Et_text span {
    font-size: 18px;
    line-height: 30px;
  }
  .Et_textContainer .ant-btn {
    font-size: 18px;
    height: 42px;
  }
}

@media (max-width: 768px) {
  .Et_container {
    width: 100%;
  }
  .Et_subContainer {
    flex-direction: column;
    padding-top: 24px;
  }

  .Et_textContainer,
  .Et_text {
    align-items: center;
    max-width: 554px;
    width: 96vw;
  }

  .Et_subText,
  .Et_text h1 {
    text-align: center;
  }

  .Et_subContainer img {
    width: 100%;
  }
}

@media (max-width: 584px) {
  .Et_text {
    gap: 8px;
  }
  .Et_text h1 {
    font-size: 30px;
  }

  .Et_text span {
    font-size: 16px;
    line-height: 26px;
  }
  .Et_textContainer .ant-btn {
    font-size: 16px;
    height: 38px;
  }
}

@media (max-width: 500px) {
  .Et_subContainer {
    padding-top: 0;
  }
  .Et_text h1 {
    font-size: 22px;
  }
}
