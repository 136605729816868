
.NotificationListComponent_container {
    display: flex;
    padding: 16px 24px 16px 16px;
    align-items: flex-start;
    gap: 16px;
    border-bottom: 1px solid #f0f0f0;
  }
  
  .NotificationListComponent_container .ant-avatar {
    height: 45px;
    width: 45px;
  }
  
  .NotificationListComponent_subContainer {
    display: flex;
    min-width: 252px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
  
  .NotificationListComponent_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    width: 100%;
  }
  
  .NotificationListComponent_title h4 {
    color: #262626;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
  }
  
  .NotificationListComponent_subContainer p {
    color: #8c8c8c;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    margin: 0;
  }
  
  .NotificationListComponent_subContainer h5 {
    color: #595959;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
  }