.popup_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4px;
    width: 160px;
    height: 170px;
    background: #FFFFFF;
    border-radius: 8px;
    position: absolute;
    top: 72px;
    right: 14px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);

    &:before {
        content: '';
        position: absolute;
        left: 86%;
        transform: translateX(-50%);
        top: -19px;
        border: 10px solid transparent;
        border-bottom-color: #FFFFFF;
    }
}

li.contents {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: start;
    gap: 8px;
    position: relative;
    padding: 4px;
    left: 9px;
    cursor: pointer;
}

.popup_name {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.88);
}

.popup_items {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.88);
}

.popup_icon1 .anticon svg {
    width: 14px;
    height: 16px;
    position: relative;
    bottom: 1px;
}