.approvals_action {
    color:#4800FF;
    font-size: 14px;
    cursor: pointer;
}

.approvals_action.disabled {
    color: rgba(0, 0, 0, 0.25);
    font-size: 14px;
    cursor: not-allowed;
  }

.approvals_title {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.approvals_title h3 {
    font-size: 16px;
    font-weight: 700;
    margin: 0;
}

.approvals_title h3 span {
    font-size: 12px;
    font-weight: 400;
}

.approvals_title span {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
}

.approvals_btn {
    border: 1px solid #52C41A !important;
    background: #52C41A !important;
    color: #FFFFFF;
}

.reject_btn {
    border: 1px solid #FF4D4F !important;
    background: #FF4D4F !important;
    color: #FFFFFF;
}

.Quotation_subtitle {
    color: #595959;
    font-weight: 700;
}
.Quotation_copy .anticon svg {
    height: 20px;
    width: 20px;
    color: #4800FF;
    transform: translateY(2px);
}

.Quotation_section .ant-tabs .ant-tabs-nav-list .ant-tabs-tab   {
    padding: 6px !important;
}