.filters {
    display: flex;
    background: #fff;
    padding: 10px;
    border-radius: 4px; 
    flex-direction: column; 
    align-items: flex-start; 
    gap: 10px; 
    margin: 16px 0
}

.filter-flex {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 8px;
}

.filter-expand {
    margin-top: -8px;
}

.filter-expand svg {
    width: 10px;
    height: 10px;
}

.filter-cross {
    margin-left: 5px;
    transform: rotateZ(45deg) translateY(3px) translateX(-3px);
    color: rgba(0, 0, 0, 0.45);
}

.filter-cross2 {
    margin-left: 5px;
    transform: translateX(-3px);
    color: rgba(0, 0, 0, 0.45);
}

.filter-cross svg {
    width: 10px;
    height: 10px;
}