.ProfileView_area {
    padding: 24px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
}

.ProfileView_container {
    display: flex;
    padding: 24px;
    align-items: flex-start;
    gap: 16px;
}

.ProfileView_photo .ant-avatar {
    height: 64px;
    width: 64px;
}
.ProfileView_info .anticon svg {
    width: 12px;
    height: 12px;
}

.ProfileView_introduction {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
}

.ProfileView_introduction h3 {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
}

.ProfileView_introduction p {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
}

.ProfileView_introduction :last-child {
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
}

.ProfileView_topButton {
    display: flex;
    gap: 18px;
}

.ProfileView_topButton Button {
    border: 1px solid #4800FF;
    background: #4800FF;
    color: #FFF;
}

.ant-message {
    top: 80% !important;
}

.ProfileView_y {
    display: flex;
    gap: 10px;
}

.ProfileView_b {
    display: flex;
    align-items: center;
    gap: 6px;
}

.ProfileView_b i {
    width: 16px;
}

.ProfileView_badge {
    z-index: -1;
}

.ProfileView_alertContainer {
    align-self: stretch;
}

.ProfileView_alertContainer .ant-alert-content {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.ProfileView_alertContainer .ant-alert-error {
    width: 100vw;
    max-width: 100%;
    padding: 12px 16px;
}

.ProfileView_alertContainer .ProfileView_alertIcon {
    display: flex;
    gap: 10px;
    align-items: center;
}

.ProfileView_alertContainer .ant-alert-message {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 0;
}

.ProfileView_container-2 {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    background: #FFF;
}

.ProfileView_container-2 .ant-btn,
.Attachment_modal .ant-modal-footer .ant-btn,
.Upload_modal .ant-modal-footer .ant-btn,
.Upload_content .ant-btn {
    border: 1px solid #4800FF;
    background: #4800FF;
    color: #FFFFFF;
}

.Upload_content .ant-upload-list-item-actions button {
    border: 1px solid #FFFFFF;
    background: #FFFFFF;
    color: #FFFFFF;
}

.ProfileView_subContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    max-width: 1700px;
}

.ProfileView_subContainer p {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
}

.ProfileView_subContainer-2 {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    width: 100%;
}

.ProfileView_subContainer-2-1 {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}

.ProfileView_x {
    display: flex;
    gap: 0px;
    width: 100%;
}

.ProfileView_details {
    display: flex;
    flex-direction: column;
    padding: 0px 0px 0px 16px;
    align-items: flex-start;
    width: 100%;
}



.ProfileView_details-2{
    display: flex;
    flex-direction: column;
    /* padding: 0px 16px; */
    align-items: flex-start;
}

.ProfileView_subDetails-2 {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    width: 100%;
    min-width: 76px;
}

.ProfileView_subDetails-2 p {
    color: #4800FF;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    cursor: pointer;
}

.ProfileView_subTitle {
    display: flex;
    align-items: center;
    gap: 4px;
}

.ProfileView_subTitleEditing {
    display: flex;
    align-items: center;
}

.ProfileView_subTitleEditing .ant-input {
    color: #4800FF;
    border-width: 0 2px 0 0;
    padding: 0;
    font-size: 16px;
    line-height: 24px;
    border-style: solid;
    border-color: #D9D9D9;
    border-radius: 2px;
    background-color: #F5F5F5;
}

.ProfileView_subTitleEditing .ant-btn {
    height: 24px;
    border-color: #D9D9D9;
    border-radius: 2px;
    background-color: #F5F5F5;
    padding: 5px;
    border: none;
    display: flex;
}

.ProfileView_subTitleEditing .ant-btn .anticon svg {
    height: 12px;
    width: 12px;
    color: #8C8C8C;
}

.ProfileView_subTitle .anticon svg {
    width: 16px;
    height: 16px;
    color: #8C8C8C;
}

.ProfileView_info {
    display: flex;
    align-items: flex-start;
    gap: 16px;
}

.ProfileView_info p {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
}

.ProfileView_subDetails {
    display: flex;
    gap: 8px;
    justify-content: space-between;
    width: 100%;
    max-width: 550px;
}

.ProfileView_subDetails span {
    display: flex;
    gap: 8px;
    align-items: center;
    color: #4800FF;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

.ProfileView_subDetails span .anticon svg {
      height: 12px;
      width: 12px;
}

.ProfileView_subDetails p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: start;
    min-width: 42px;
}

.ProfileView_middle {
    width: 100%;
    padding-right: 28px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.ProfileView_middle-2 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
}
.ProfileView_middle-2 p {
    color: #595959;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px; 
    margin: 0;
}

.ProfileView_middle-2 span {
    color:  #262626;
    padding-left: 8px;
}
.ProfileView_label {
    color: #595959;
    min-width: 68px !important;
}

.ProfileView_middle-2 span .anticon svg {
    color: #4800FF;
}

.ProfileView_middle .ant-btn {
    display: flex;
    padding: 4px 15px;
    align-items: center;
    gap: 4px;
}

.ProfileView_grant {
    display: flex;
    align-items: center;
    gap: 4px;
}
.ProfileView_grant span {
    color: #4800FF;
    font-size: 14px;
    font-weight: 400;
}

.ProfileView_grant img:last-child {
    height: 16px;
    width: 16px;
}

.ProfileView_tickPopover .ant-popover-content {
    left: 14px;
}

.ProfileView_heading {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.ProfileView_heading h3 {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
}

.ProfileView_heading p {
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
}

.ProfileView_notesButton {
    display: flex;
    padding: 4px 15px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    border: 1px solid #4800FF;
    background: #4800FF;
    color: #FFF;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
}

.ant-modal .ant-modal-content {
    border-radius: 0;
    padding: 24px;
}

.ant-modal .ProfileView_notesModal {
    width: 520px !important;
}

.ProfileView_notesModalInput{
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.ProfileView_notesModal .ant-input {
    border-radius: 3px !important;
    white-space: pre-wrap;
}

.ProfileView_notesModalInput-2 .ant-input {
    height: 28px !important;
}

.ProfileView_notesModalInput-2{
    height: 38px !important;
    border-radius: 3px !important;
}

.hover-icon-0:hover .anticon,
.hover-icon-1:hover .anticon,
.hover-icon-2:hover .anticon{
    visibility: visible;
  }

.ProfileView_notesModalInput-2 .anticon {
  visibility: hidden;
  color: #434343;
}

.ProfileView_notesModalInput-2_detail{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.ProfileView_notesModalInput-2_detail.block {
    display: block;
}
.ProfileView_notesModalInput-2_detail span:first-child {
    color: #4800FF;
}
.ProfileView_notesModalInput-2_detail span:last-child {
    color:#00000073;
}

.ProfileView_notesModalInput-2_detail span .anticon svg {
    height: 22px;
    width: 22px;
    color: #4800FF;
    transform: translateY(4px);
}

.ant-modal .ant-modal-header {
    margin-bottom: 0px;
}

.ProfileView_notesModalHeading {
    display: flex;
    padding: 16px 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    background: #FFF;
    box-shadow: 0px -1px 0px 0px #F0F0F0 inset;
}

.ProfileView_notesContainer {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}


.Option_popup {
    position: absolute;
    background-color: #fff;
    padding: 8px;
    z-index: 1;
    filter: drop-shadow(0px 9px 28px rgba(0, 0, 0, 0.05)) drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.08)) drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.12));
    border-radius: 8px;
    right: 54px;
}

.Option_popup p {
    cursor: pointer;
    margin: 0;
    padding: 8px;
}

.Option_popup p:hover {
    background-color: #f0f0f0;
}

.list_popover .ant-popover-inner{
    padding: 6px;
}

.profile_popover .ant-popover-inner{
    padding: 4px;
    position: relative;
    right: 18px;
}

.list_popup,
.profile_popup {
    display: flex;
    flex-direction: column;
}

.list_popup span,
.profile_popup span,
.profile_popup a {
    color: rgba(0, 0, 0, 0.88);
    padding: 5px 12px;
    border-radius: 4px;
    cursor: pointer;
}

.list_popup span:hover,
.profile_popup span:hover,
.profile_popup a:hover{
    background-color: #f0f0f0;
}

.BulkUpload_loading .custom-spin .anticon svg {
    height: 25px !important;
    width: 25px;
    color: #4800FF;
}

.custom-spin {
    display: inline-flex;
    position: fixed;
    padding: 8px 14px;
    align-items: center;
    gap: 8px;
    top: 81%;
    transform: translateX(-50%);
    left: 50%;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
}


/* ------------------------------------------------------------------------------------------------------ */


  .Attachment_modal{
    max-width: 508px
   }

   .Attachment_content {
    display: flex;
    padding: 24px 40px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
   }
   
   .Attachment_content p {
    color: #262626;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
   }



    .Attachment_modal .ant-modal-content,
    .Upload_modal .ant-modal-content{
        padding: 0 !important;
    }
    
    .Upload_modal .ant-modal-title {
        padding: 16px 24px;
        box-shadow: 0px -1px 0px 0px #f0f0f0 inset;
        font-size: 16px;
        font-weight: 500;
        margin: 0;
    }

    .Upload_modal .ant-modal-header {
        margin-bottom: 0 !important;
    }
    
   .Attachment_modal .ant-modal-footer,
   .Upload_modal .ant-modal-footer {
        padding: 10px 16px;
        justify-content: flex-end;
        box-shadow: 0px 1px 0px 0px #f0f0f0 inset;
        margin: 0;
   }

   .Upload_modal {
        max-width: 712px; 
   }

   .Upload_content {
        display: flex;
        padding: 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
   }

   .Upload_content .ant-space,
   .Upload_content .ant-space .ant-space-item,
   .Upload_content .ant-space .ant-space-item .ant-select {
        width: 100%;
   }

   .Upload_content .ant-select-selection-item,
   .Upload_content .ant-select-selection-placeholder {
        display: flex;
        align-items: center;
    }

   .Upload_content .ant-select-selector,
   .Upload_content .ant-input,
   .Upload_content .ant-picker {
        padding: 8px 12px !important;
        height: 34px !important;
    }

    .Upload_content .ant-upload-wrapper {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
    }

    /* .Upload_content .ant-upload-wrapper .ant-upload-select {
        position: relative;
        left: 490px;
    } */
    .Upload_content .ant-upload-wrapper .ant-upload-select .disabled {
        opacity: 0.6;
        background-color: #8C8C8C;
        border: 1px solid #8C8C8C;
    }

    .Upload_subContent {
        display: flex;
        align-items: flex-end;
        gap: 12px;
        width: 100%;
    }

    .Upload_subContent-2 {
        display: flex;
        /* width: 530px; */
        flex: 1;
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;
    }
    .ant-drawer-mask{
        background-color: rgba(0, 0, 0, 0.05) !important;
    }

    .ProfileView_area .ProfileView_subContainer-2 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2px; /* Adjust the gap as needed */
      }
      
      
      .ProfileView_y {
        display: flex;
        flex-direction: column;
      }
      
      .ProfileView_b {
        display: flex;
        justify-content: end;
        align-items: center;
      }
      
      .ProfileView_details-2 {
        display: flex;
        justify-content: flex-end;
      }
      
      
      .Quotes_modal .ProfileView_info .anticon svg {
        width: 12px;
        height: 12px;
        padding: 5px 5px 0px !important;
      }