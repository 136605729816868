.categories-container {
    margin-top: 20px;
}

.category-item {
    position: relative;
    width: 100%;
    height: 200px;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s ease;
}

.category-item:hover {
    transform: scale(1.05);
}

.category-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    filter: blur(5px);
    /* Initial blur effect */
    transition: filter 0.3s ease;
    /* Add filter transition */
}

.category-item:hover .category-background {
    filter: blur(0);
    /* Remove blur on hover */
}

.category-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* Ensure the overlay covers the entire item */
    background: rgba(0, 0, 0, 0.8);
    color: white;
    display: flex;
    /* Use Flexbox */
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    text-align: center;
    /* Center text */
    padding: 10px;
    transition: background 0.3s ease;
}

.category-overlay:hover {
    background: rgba(0, 0, 0, 0.1);
}

.profile-card {
    text-align: left;
    padding: 0px !important;
}

.profile-card .anticon svg {
    height: 16px;
    width: 16px;
}

.profile-card .ant-tag .anticon svg {
    font-size: 12px;
    height: 12px;
    width: 12px;
}

.profile-card .ant-card-body {
    padding: 5px 10px 10px 10px;
}

.star-section :hover {
    cursor: pointer;
}
