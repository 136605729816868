.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    line-height: 24px;
}

.popup-content p {
    margin: 0;
    font-size: 16px;
}

.popup-content .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    color: #333;
    cursor: pointer;
}

.close {
    height: 30px;
    width: 30px;
    background-color: white;
    border-radius: 50%;
}

.close span {
    position: absolute;
    top: 3px;
    right: 8.5px;
}

.popup-content .close:hover {
    color: #000;
}

.home-button {
    padding: 12px 24px;
    font-size: 16px;
    background-color: #3A00CC;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}

.home-button:hover {
    background-color: #3A00CC;
}