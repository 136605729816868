.Uv_container{
    top: 86px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
}

.Uv_subContainer{
    padding: 24px;
    border-radius: 8px;
    width: 960px;
    height: 890px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Uv_subContainer.greenBG{
    background: #E0FFF9;
}

.Uv_subContainer.blueBG {
   background: #F0EBFF;
}

.Uv_content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;
    flex: 1 0 0;
    align-self: stretch;
}

.Uv_heading{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}

.Uv_heading h1{
    color: #008065;
    font-size: 30px;
    font-weight: 500;
    line-height: 40px; 
    margin: 0;
}

.Uv_heading.greenColor h1 {
    color: #008065;
}

.Uv_heading.blueColor h1{
    color: #3A00CC;
}

.Uv_button.greenColor .ant-btn{
  background: #008065 !important;
  border: 1px solid #008065;
  margin-bottom: 18px;
}

.Uv_button.blueColor .ant-btn{
    background: #3A00CC;
    border: 1px solid #3A00CC;
    margin-bottom: 18px;
}

.Uv_button.blueColor a {
    color: #3A00CC;
    padding-top: 10px;
    font-size: 14px;
    text-decoration: none;
}

.Uv_button.greenColor a {
    color: #008065;
    padding-top: 10px;
    font-size: 14px;
    text-decoration: none;
}

.Uv_button.greenColor a:hover,
.Uv_button.blueColor a:hover {
 text-decoration: underline;
}

.Uv_heading p {
    color: #595959;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin: 0;
}

.Uv_button .ant-btn {
    display: flex;
    padding: 4px 15px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #fff;
}
.Uv_skip{
    position: relative;
    left: 412px;
}