/* CSS below this line will remove later */
.ProfileView_area {
    padding: 24px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
}

.ProfileView_container {
    display: flex;
    padding: 24px;
    align-items: flex-start;
    gap: 16px;
}

.ProfileView_photo .ant-avatar {
    height: 64px;
    width: 64px;
    display: flex;
    align-items: center;
    font-size: 38px;
    color: #FA541C;
}

.ProfileView_introduction {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
}

.ProfileView_introduction h2 {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
}

.ProfileView_introduction p {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
}


.ProfileView_topButton Button {
    border: 1px solid #4800FF;
    background: #4800FF;
    color: #FFF;
}

.account_container-2{
    display: flex;
    padding: 24px 24px 0 24px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

/* do not remove the CSS below this line  */


.VendorOnboard_adding-1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 4px;
    width: 100%;
}

.Registration_firstBox-1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    gap: 12px;
    background-color: #FFF;
}

.Registration_input {
    padding-bottom: 24px;
}

.EnterpriseInfo_Button Button {
    color: #FFF;
    border-radius: 8px;
    border: 1px solid #4800FF;
    background: #4800FF;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
}