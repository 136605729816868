body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Home_container {
  width: 100%;
  top: 85px;
  position: relative;
}

.Home_firstContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 24px;
  gap: 14px;
  background: #FFFFFF;
}


.Home_heading {
  display: flex;
  align-items: center;
  position: relative;
  gap: 16px;
  width: 100%;
}

.Home_headingButton .ant-btn {
  background-color: #4800FF;
}

.Home_heading h1 {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.88);
  margin: 0;
}

.Home_heading h1 span {
  font-size: 13px;
}


.Home_tabInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.Home_tabInfo .ant-tabs-nav-operations {
  display: none !important;
  visibility: hidden !important;
}

.Home_tabInfo .ant-tabs-top >.ant-tabs-nav {
  margin-bottom: 0 !important;
}

.Home_tabInfo .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #4800FF !important;
}

.Home_tabInfo .ant-tabs-ink-bar  {
  background: #4800FF !important;
}

.Home_tabStatus {
  display: flex;
  align-items: center;
  gap: 8px;
}

.Home_tabStatus span {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  padding: 1px 8px;
  border-radius: 2px;
}

.Home_tabStatus span:first-child {
  border: 1px solid #FFA39E;
  background: #FFF1F0;
  color: #F5222D;
}

.Home_tabStatus span:last-child {
  border: 1px solid #FFD591;
  background: #FFF7E6;
  color: #FA8C16;
}
