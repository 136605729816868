.Dashboard_conatiner {
  display: flex;
  padding: 28px 40px 20px 30px;
  align-items: center;
  justify-content: space-between;
}

.Dashboard_leftSubConatiner {
  display: flex;
  align-items: center;
  gap: 16px;
}

.Dashboard_title {
  display: flex;
  align-items: center;
  gap: 8px;
}

.Dashboard_title h4 {
  color: #434343;
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}

.Dashboard_title .ant-avatar {
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  font-size: 24px;
  color: #FA541C;
}

.Dashboard_leftSubConatiner p {
  color: #4800ff;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  transform: translateY(1px);
  margin: 0;
}

.Dashboard_rightSubConatiner {
  display: flex;
  align-items: center;
  gap: 20px;
}

.Dashboard_rightSubConatiner .anticon svg {
  height: 24px;
  width: 24px;
  color: #4800ff;
  cursor: pointer;
}

.Dashboard_notificationContainer {
  position: relative;
  padding: 6px 8px;
  border-radius: 8px;
}

.Dashboard_notificationContainer.active,
.Dashboard_notificationContainer:hover {
  background: #f0ebff;
}

.Dashboard_notification {
  width: 10px;
  height: 10px;
  position: absolute;
  right: 9px;
  border-radius: 50%;
  top: 9px;
  border: 0.5px solid #fafafa;
  background: #e72727;
}

.popover-no-arrow .ant-popover-arrow {
  display: none;
}

.Dashboard_rightSubConatiner h5 {
  color: #434343;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.Dashboard_container-2 {
  display: flex;
  padding: 0px 30px 20px 30px;
  align-items: flex-start;
  gap: 20px;
}

.Dashboard_modal .ant-modal-content {
  padding: 0;
}

.Dashboard_modal .ant-modal-title {
    padding: 16px 24px;
    box-shadow: 0px -1px 0px 0px #f0f0f0 inset;
    width: 393px;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
}
.Dashboard_modal .anticon svg {
    transform: translateY(2px);
 }

.Dashboard_modalContent {
  display: flex;
  padding: 0 16px 16px 16px;
  flex-direction: column;
  align-items: center;
}

.edit .ant-modal-header {
  margin-bottom: 0;
}

.disabledButton {
  border: 1px solid rgba(0, 0, 0, 0.25) !important;
  background: rgba(0, 0, 0, 0.25) !important;
}

.Dashboard_modalContent .ant-avatar {
  height: 160px;
  width: 160px;
  flex-shrink: 0;
  margin: auto;
  font-size: 16px;
  color: #434343;
  background-color: #F0F0F0;
  display: flex;
  align-items: center; 
}

.Dashboard_title span .logo-text {
  color:#434343;
  font-size: 10px;
  font-weight: 500;
}
  
.Dashboard_titleSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
}

.Dashboard_titleSection p {
  margin: 0;
  color: #595959;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; 
}

.Dashboard_modalIcon {
  width: 30px;
  height: 30px;
  background: #4800ff;
  position: absolute;
  top: 234px;
  right: 134px;
  border-radius: 50%;
}

.Dashboard_modalInput {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.Dashboard_modalIcon .anticon svg {
  color: white;
  transform: translate(7px, 5px);
  width: 16.875px;
  height: 17px;
}

.Dashboard_modalContent .ant-input {
  border-radius: 6px 0px 0px 6px;
  padding: 5px 12px;
  border-radius: 3px;
  width: 100%;
}

.Dashboard_modal .ant-modal-footer {
  padding: 10px 16px;
  justify-content: flex-end;
  box-shadow: 0px 1px 0px 0px #f0f0f0 inset;
  margin: 0;
}

.Dashboard_modal .ant-btn {
  border: 1px solid #4800ff;
  background: #4800ff;
  color: #fff;
}

.Dashboard_modalInput .ant-btn {
  display: flex;
  padding: 4px 15px;
  height: 34px;
  justify-content: center;
  align-items: center;
  border-radius: 0px 6px 6px 0px;
}


.Dashboard_conatiner-3 {
  display: flex;
  align-items: flex-start;
}

.Dashboard_subConatiner-3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.TaskListsComponent_container {
  padding: 0px 30px 20px 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.TaskListsComponent_container h4 {
  color: #4800FF;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; 
  margin: 0;
}

.TaskListsComponent_subContainer {
  padding: 20px 32px 20px 20px;
  border-radius: 6px;
  background: #FFF;
  width: 100%;
}

.TaskListsComponent_typography{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  max-height: 350px;
  padding-right: 28px;
  overflow-y: auto;
  width: 100%;
}

.TaskListsComponent_typography .ant-typography {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    padding: 15px;
    margin-bottom: 0;
    width: 100%;
 }

 .TaskListsComponent_typography .ant-avatar{
   background-color:  #F0EBFF;
 }

 .TaskListsComponent_typography .ant-avatar .anticon svg {
   height: 20px;
   width: 20px;
   color: #4800FF;
 }

 .TaskListsComponent_text{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
 }

 .TaskListsComponent_text h1{
  color: #262626;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
 }

 .TaskListsComponent_text p {
  color: #595959;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; 
  text-align: start;
  margin: 0;
 }

 .TaskListsComponent_time p{
  color: #8C8C8C;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px; 
  margin: 0;
  min-width: 63px;
 }

 /* scroll bar CSS */
 .TaskListsComponent_typography::-webkit-scrollbar {
  width: 8px;
}

.TaskListsComponent_typography::-webkit-scrollbar-thumb {
  background-color:#8C8C8C; 
  border-radius: 6px; 
}

.TaskListsComponent_typography::-webkit-scrollbar-thumb:hover {
  background-color: #555; 
}

.TaskListsComponent_typography::-webkit-scrollbar-track {
  background: #D9D9D9;
  border-radius: 2px;
  box-shadow:
    inset 0 0 0 3px #fff,
    inset 0 0 0 3px #fff; 
}
.TaskListsComponent_typography .colorG {
  background: #E0FFF9;
}

.colorG .anticon svg{
  color: #008065 !important;
}








