.Profile{
    width: 100%;
    top: 85px;
    position: relative;
}
.Profile_container{
    display: flex;
    max-width: 1425px;
    min-width: 960px;
    width: 70vw;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

}

.Profile_firstContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
}

.Profile_firstTopContainer{
    height: 120px;
    flex-shrink: 0;
    align-self: stretch;
    background: #D9D9D9;
}

.Profile_ImageContainer{
    width: 100%;
    padding: 0 12px 0 25px;
    position: relative;
    top: 75px;
    display: flex;
    justify-content:space-between;
}

.Profile_firstTopContainer .ant-avatar {
    display: flex;
    width: 88px;
    height: 88px;
    align-items: center;
    justify-content: center;
    border: 4px solid #FFF;
    background-color: #BFBFBF;
}

.Profile_firstTopContainer .ant-avatar .anticon svg {
    width: 32px;
    height: 32px;
}

.Profile_firstTopContainer .ant-btn {
    color: #8C8C8C;
}

.Profile_firstTopContainer .ant-btn .anticon svg {
    height: 14px;
    width: 14px;
}

.Profile_firstTopContainer .ant-btn .anticon-edit {
    margin-left: 0;
}

.Profile_editIcon {
    padding: 8px;
    position: absolute;
    left: 82px;
    top: 50px;
    border-radius: 32px;
    background: #FFF;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
}

.Profile_editIcon .anticon svg {
    width: 16px;
    height: 16px;
}

.Profile_firstBottomContainer{
    display: flex;
    padding: 60px 24px 24px 24px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    background: #FFF;
}

.Profile_nameContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
    width: 100%;
    padding: 7px 0;
}

.Profile_nameContainer span {
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
}

.Profile_nameContainer .anticon svg {
    height: 20px;
    width: 20px;
    color: #8C8C8C;
    transform: translateY(2px);
}

.Profile_secondContainer {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    border-radius: 8px;
    background: #E0FFF9;
}

.Profile_coupon {
    display: flex;
    padding: 8px 0px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    align-self: stretch;
}

.Profile_couponTitle {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-self: stretch;
}

.Profile_couponTitle h4 {
    color: #008065;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    margin: 0;
}

.Profile_couponTitle span {
    color:  rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px; 
}

.Profile_coupon .ant-input-group-wrapper {
    display: flex;
    width: 294px;
    align-items: center;
    border-radius: 8px;
    border: 1px #D9D9D9;
}

.Profile_coupon .ant-input-group-addon {
    background-color: #FAFAFA;
}

.Profile_planContainer,
.Profile_planCardBottom-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.Pw_switchGroup span,
.Profile_planCardTop-1 span {
    color:  #434343;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.Profile_planCards {
    display: flex;
    align-items: center;
    gap: 3vw;
}

.Profile_planCard-1{
    display: flex;
    padding: 16px 24px 20px 24px;
    flex-direction: column;
    max-width: 500px;
    align-items: center;
    gap: 30px;
    border-radius: 6px;
    background: #FFF;
}

.Profile_planCardTop-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 160px;
}

.Profile_planCardTop-1 h4 {
    color:  #434343;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    margin: 0;
}

.Profile_planCardTop-1 h2 {
    color: #008065;
    font-size: 32px;
    font-weight: 500;
    line-height: 40px; 
    margin: 0 0 4px 0;
}

.Profile_planCardBottom-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.Profile_planCardContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.Profile_planCardPoints {
    display: flex;
    align-items: center;
    gap: 8px;
}

.Profile_planCardBottom-1 p {
    color:  #434343;
    font-size: 14px;
    text-align: start;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
}

.Profile_planCardBottom-1 .anticon svg {
    height: 13px;
    width: 13px;
    transform: translateY(2px);

}

.Profile_cardPayIcon {
    display: flex;
    align-items: center;
    gap: 8px;
}

.Profile_cardFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.Profile_cardFooter .ant-btn {
    padding: 4px 48px;
    border: 1px solid #008065;
    background: #E0FFF9;
}
.Profile_cardFooter .ant-btn span {
    color: #008065 !important;
    font-size: 14px;
}

.payCard_plan {
    position: relative;
    padding: 36px 40px 44px 40px;
    gap: 36px;
    border: 1.5px solid #008065;
}

.payCard_button .ant-btn {
    border: 1px solid #008065;
    background: #008065;
}

.payCard_button .ant-btn span {
    color: #FFF !important;
}

.Profile_planCardText {
    padding: 0px 8px;
    color: #FFF !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    border-radius: 12px;
    background: #008065;
    position: absolute;
    top: -12px;
    right: 14px;
    line-height: 20px; 
}

/* below this line css  is duplicate need to remove it  */
.Pw_switchGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}

.Pw_switch {
    display: flex;
    padding: 8px 30px;
    align-items: center;
    gap: 16px;
    border-radius: 8px;
    border: 1.5px solid  #D9D9D9;
}

.Pw_switch span {
    color:  #434343;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; 
}

.Pw_switch .ant-switch-inner {
    background-color: #B9D2F7;
    box-shadow: inset 0 0 0 0.5px #5491ed;
}

.Pw_switch .ant-switch .ant-switch-handle::before {
    background-color: #3c80ea;
  }
