.list {
    padding: 24px;
    min-height: 100vh;
    height: 100%;
}

.tabs {
    padding: 12px 24px;
    display: flex;
    align-items: center;
    column-gap: 12px;
    border-radius: 12px;
    background: #fff;
    margin-bottom: 8px;
    cursor: pointer;
}

.tabsActive {
    background: #F0EBFF;

}

.tabText {
    flex: 1;
    color: rgba(0, 0, 0, 0.45);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
}

.tabTextActive {
    color: #4800FF;
    font-weight: 500;
}


.categoryBox span {
    width: 4px;
    height: 4px;
    background-color: #D9D9D9;
    border-radius: 50%;
    display: block;
}

.category {
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
}


.productContainer {
    padding: 12px;
    position: relative;
    background-color: #fff;
    border-radius: 12px;
    margin-bottom: 12px;
}

.location {
    color: rgba(0, 0, 0, 0.85);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.draftIcon svg {
    width: 24px;
    height: 24px;
    color: inherit;
}

.description {
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    text-align: justify;
}

.imageBox {
    position: relative;
    margin-top: 22px;

    min-width: 100%;
    min-height: 260px;
}

.carousel img {
    height: 260px;
    width: 100%;
    object-fit: cover;
}

.variantBox {
    border-radius: 32px;
    background: rgba(0, 0, 0, 0.20);
    padding: 4px;

    position: absolute;
    right: 8px;
    bottom: 8px;

    display: flex;
    gap: 4px;
}

.customsedBox {
    border-radius: 32px;
    background: rgba(0, 0, 0, 0.20);

    position: absolute;
    right: 8px;
    bottom: 34px;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.playbtnBox {
    border-radius: 32px;
    background: rgba(0, 0, 0, 0.20);

    position: absolute;
    right: 8px;
    top: 8px;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.productVariant {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid #EEEEEE;
    cursor: pointer;
}

.productVariantNA {
    text-align: center;
    width: 45px;
    height: 16px;
    border-radius: 12px;
    border: 2px solid #EEEEEE;
    cursor: pointer;
    font-size: 10px;
}

.productVariantNA span {
    display: block;
    line-height: 14px;
}

.statsBox {
    padding-top: 12px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
}

.attributes {
    display: flex;
    align-items: center;
    gap: 8px;
}

.attributes p {
    color: rgba(0, 0, 0, 0.85);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 10px;
}

.dotSpan {
    width: 4px;
    height: 4px;
    background-color: #D9D9D9;
    border-radius: 50%;
    display: block;
}

.searchIcon {
    font-size: 24px;
    color: #4800FF;
    cursor: pointer;
}

.searchBox {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px 12px;
    border-radius: 12px;
    background: #fff;
}