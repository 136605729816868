@import url("../../fonts.css");

.Navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    z-index: 99;
    position: fixed;
    width: 100%;
    height: 86px;
    left: 0px;
    top: 0px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
}

.logo-module {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding-top: 60px;
    padding-right: 60px;
    padding-left: 60px;
    padding-bottom: 23px;
    position: relative;
    bottom: 19px;
    gap: 12px;
    width: 145px;
    height: 40px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.logo {
    width: 40px;
    height: 40px;
    border-radius: 10px;

    flex: none;
    order: 0;
    flex-grow: 0;
}
.logo img {
    width: 40px;
    height: 40px;
}

.title img {

    width: 93px;
    height: 31px;
}

.Links-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 23px;
    gap: 45px;
    position: relative;
    right: 24px;
    height: 40px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

div .profile,
div .profile img{
    height: 32px;
    width: 32px;
    padding-left: 0;
    padding-right: 0;
    cursor: pointer;
    border-radius: 50%;
}

.Link span {
    top: 5px;
    color: #FA541C;
    position: relative;
}

.Links-container li {
    height: 25.2px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;
    padding: 6.4px 1px;
}

.Link {
    padding-left: 15px;
    padding-right: 15px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    text-align: center;

    color: rgba(0, 0, 0, 0.85);
    text-decoration: none;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.Navbar ul {
    list-style-type: none;
}

a:active,
.open-link-container-button:active {
    -webkit-tap-highlight-color: transparent;
}

.color_contact {
    background-color: #3A00CC;
    border: 1px solid #3A00CC;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 8px;
    color: #FFFFFF;
    cursor: pointer;


}

.hover1:hover {
    color: #3A00CC;
}

.close-link-container-button-container,
.open-link-container-button,
.close-link-container-button,
.nav_headphone_icon {
    display: none;
}

/* -----------------Responsive Design--------------------------- */

@media only screen and (max-width: 1100px) {
    .Navbar {
        align-items: center;
    }

    .logo-module {
        padding-top: 60px;
        padding-right: 60px;
        padding-left: 32px;
        padding-bottom: 23px;
        align-items: center;
        gap: 6px;
    }
    .Links-container {
        gap: 20px;
      }

    .title img {
        width: 70px;
        height: 26px;
    }

    .Links-container {
        justify-content: center;
        padding: 10px;
        right: 0;
        margin-top: 10px;
    }

    .Links-container li {
        width: auto;
        height: auto;
        padding: 5px;
    }

    .Link {
        padding-left: 12px;
        padding-right: 12px;
        font-size: 15px;
        line-height: 22px;
    }


}

@media only screen and (max-width: 868px) {


    .title img {
        width: 60px;
        height: 20px;
    }

    .Links-container li {
        padding: 4px;
    }

    .Link {
        padding-left: 8px;
        padding-right: 8px;
        font-size: 13px;
        line-height: 18px;
    }

}

@media (max-width: 768px) {
    .Navbar {
        height: auto;
        gap: 0;
        flex-direction: column;
        align-items: center;
        padding-top: 10px;
    }

    .logo-module {
        padding: 0;
        top: -6px;
        justify-content: center;
    }

    .title img {
        width: 70px;
        height: 23px;
    }

    .Links-container {
        justify-content: center;
        padding: 0;
        right: 0;
        margin-top: 0;
        margin-bottom: 0;
    }

    .Links-container li {
        width: auto;
        height: auto;
        padding: 5px;
    }

    .Link {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 14px;
        line-height: 20px;
    }

}

@media (max-width: 584px) {

    .title img {
        width: 50px;
        height: 16px;
        position: relative;
        top: 1px;
    }

    .logo {
        height: 28px;
        width: 28px;
    }

    .logo img {
        height: 28px;
        width: 28px;
    }

    .Links-container li {
        padding: 3px;
        flex-direction: row;
    }

    .Link {
        padding-left: 6px;
        padding-right: 6px;
        font-size: 12px;
        line-height: 16px;
    }
}

@media (max-width: 500px) {
    .Navbar {
        padding-top: 0;
        box-shadow: inset 0px -1px 0px #F0F0F0;
        border-radius: 0px 0px 24px 24px;
    }

    .logo-module {
        padding: 0px;
        gap: 12px;
        top: 0.9px;
        height: 28;
    }

    .nav_divider {
        width: 100%;
        background-color: #FFFFFF;
        border-bottom: 1px solid #F0F0F0;
    }

    .title img {
        width: 70px;
        height: 23px;
    }

    .logo {
        height: 28px;
        width: 28px;
    }

    .logo img {
        height: 28px;
        width: 28px;
    }

    .Links-container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 22px;
        height: auto;
    }

    .Links-container li {
        padding: 5px;
        margin: 0;
    }

    .Link {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }


    .responsive_logo-area {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .close-link-container-button-container {
        text-align: center;
        display: block;
    }

    .close-link-container-button {
        display: block;
        padding-bottom: 27px;
        cursor: pointer;
    }

    .open-link-container-button {
        padding-left: 22px;
        display: block;
        cursor: pointer;
    }

    .nav_headphone_icon {
        height: 22px;
        width: 22px;
        display: block;
        padding-right: 22px;
        cursor: pointer;
    }
}

/* -----------------Responsive Design--------------------------- */