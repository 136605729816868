.ant-layout {
    width: 100%;
}
.ant-layout-sider-trigger {
    display: none !important;
}

.ant-menu-light .ant-menu-item-selected {
    background-color: #F0EBFF;
}

.sidebar-sider .ant-menu-light .ant-menu-item-selected {
    background-color: #E0FFF9;
}

.sidebar-container {
    bottom: 0;
    position: absolute;
}

.sidebar-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-collapse {
    height: 40px;
    width: 200px;
    border-radius: 0;
    display: flex;
    align-items: center;
    position: relative;
    padding: 17px !important;
}

/* .anticon svg {
    width: 16px;
    height: 16px;
} */

.ant-menu-inline.ant-menu-root .ant-menu-submenu-title>.ant-menu-title-content {
    text-align: start;
}

.ant-menu-inline.ant-menu-root .ant-menu-submenu-title>.ant-menu-title-content {
    font-size: 14px;
    font-weight: 400;
}

.ant-menu-light .ant-menu-item-selected,
.ant-menu-light .ant-menu-submenu-selected>.ant-menu-submenu-title {
    color: #4800FF;
    border-radius: 0;
}

.sidebar-sider .ant-menu-light .ant-menu-item-selected,
.sidebar-sider .ant-menu-light .ant-menu-submenu-selected>.ant-menu-submenu-title {
    color: #006A54;
    border-radius: 0;
}

.ant-menu-light.ant-menu-inline .ant-menu-item {
    padding-left: 44px !important;
    border-radius: 0;
    margin: 7.5px !important;
}

.ant-menu-light .ant-menu-submenu-title:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected) {
    border-radius: 0;
}

.ant-menu>.ant-menu-item-selected {
    border-right: 3px solid #4800FF;
}

.sidebar-sider.ant-menu>.ant-menu-item-selected {
    border-right: 3px solid #006A54;
}

.sidebar-menu.ant-menu-item-selected {
    border-right: 3px solid #006A54;
    color: #006A54;
}

.sidebar-menu.ant-menu-item-selected {
    background-color: #E0FFF9;
}

.ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline {
    background: #ffffff !important;
}

.ant-menu-title-content {
    display: flex;
}

.ant-layout .ant-layout-sider-has-trigger {
    padding-bottom: 48px;
    min-height: 944px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}


.content-transition.collapsed {
    margin-left: 16px;
}