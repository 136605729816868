@import url("../../fonts.css");

.login {
    position: absolute;
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    background: #FFFFFF;
}

.left-container {
    position: fixed;
    display: flex;
    overflow-x: hidden;
    width: 58.33%;
    height: 100%;
    left: 0%;
    top: 5%;
}

.right-container {
    position: absolute;
    width: 41.67%;
    height: 1120px;
    left: 58.33%;
    top: 0;
    justify-content: center;
    scroll-behavior: auto;
    background: #CCFFF4;
}
.right-subContainer{
    max-width: 600px;
    width: 41vw;
    position: absolute;
    top: 86px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.app-title-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    padding: 35px 0 16px 0;
}

.app-title img {
    width: 121px;
    height: 40px;
}

.app-motto-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin: 0 12px;
    color: #1D0066;
}

.app-logo {
    width: 47.471px;
    height: 47.472px;    
}

.login-box-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 0 24px 0;
    gap: 16px;
}

.login-box-text {
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
    color: #000000;
    opacity: 0.7;
}

.login-box-input-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

}


.login-box-input-container2 {
    width: 25vw;
    max-width: 360px;
    padding: 4px 12px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
}


.password-icon {
    width: 15.57px;
    height: 13.76px;
}

.login-box-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.login-box-button-container-button,
.login-box-button-container-button.disabled-button {
    padding: 9px 12px;
    max-width: 360px;
    width: 25vw;
    height: 40px;
    background: #3A00CC;
    border: 1px solid #3A00CC;
    margin-bottom: 36px;
}

.login-box-button-container-button-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
}

.login-box-button-container-link-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #597EF7;
}

.login-social-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.login-hr-tag {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.login-hr-tag div{
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 360px;
    width: 25vw;
    gap: 12px;
}

.login-hr-tag span {
    color: rgba(0, 0, 0, 0.45);
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
}

.hr {
    width: 360px;
    border: 1px solid #BFBFBF;
}

.login-text-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.login-text-option span {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
}

.login-option {
    display: flex;
    gap: 10px;
}

.login-sso-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6.4px 15px;
    gap: 10px;
    width: 106px;
    height: 38.8px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 8px;
}

.login-sso-container2,
.login-sso-container3 {
    width: 49.8px;
    height: 38.8px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6.4px 16px;
    background: #FFFFFF;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 8px;
}

.login-sso-container img {
    width: 14px;
    height: 14px;
}

.login-sso-container2 img,
.login-sso-container3 img {
    width: 18px;
    height: 18px;
}

.login-sso-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.88);
}

.login-bottom-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    margin: 0 12px;
}

.login-bottom-text-container-top {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.45);
}

.login-bottom-text-container-middle {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: rgba(0, 0, 0, 0.45);
}
.login-bottom-text-container-middle a,
.register-box-container p a{
    color:  #4800FF;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-decoration: none;
}

.login-bottom-text-container-middle a:hover,
.register-box-container p a:hover {
    text-decoration: underline;
}

.login-bottom-text-container-bottom {
    font-weight: 400;
    font-size: 14px !important;
    line-height: 22px;
    text-align: center;
    color: rgba(0, 0, 0, 0.45);
    margin: 0;
}

.login-box-button-container .ant-checkbox-wrapper {
    padding-bottom: 24px;
}

.login-box-button-container .ant-checkbox-wrapper p {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px; 
    margin: 0;
    text-align: start;
}

.login-box-button-container .ant-checkbox-wrapper p span {
    color: #4800FF;
}

.register-box-container,
.login-welcome-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 32px 0;
}
.register-box-container p {
    color: rgba(0, 0, 0, 0.45);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
}

.register-bottom-text-container{
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}

.login-welcome-container{
    padding: 112px 0;
}

.login-welcome-container h1 {
    font-size: 30px;
    font-weight: 500;
    line-height: 40px;
    opacity: 0.7;
    margin: 0;
}
.login-welcome-container h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    opacity: 0.7;
    margin: 0;
}

.login-choose-button{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    min-width: 364px;
    width: 100%;
    height: 110px;
}

.login-choose-button .ant-btn{
    display: flex;
    padding: 24px;
    justify-content: center;
    align-items: center;
    flex: 1 0;
    height: 100%;
    position: relative;
    align-self: stretch;
}

.login-choose-button .ant-btn.active ,
.login-submit-button .ant-btn.active  {
    background-color: #4800FF;
    color: #FFFFFF;
}

.login-submit-button .ant-btn.disabled{
    background-color: #FFFFFF;
}

.login-choose-button .ant-btn .anticon svg {
    display: none;
}

.login-choose-button .ant-btn.active .anticon svg {
  display: inline-block;
}

.login-submit-button .ant-btn {
    display: flex;
    width: 360px;
    height: 40px;
    padding: 6.4px 15px;
    justify-content: center;
    align-items: center;
}

.error-message {
    color: red;
}