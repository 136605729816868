.Approval_Reject {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.Approval_Reject TextArea{
    border-radius: 2px !important;
}

.Approval_Reject_Mend {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Approval_Reject_Mend span {
    color: #595959;
    font-size: 12px;
}