* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Roboto';
}
 .App {
   /* this CSS is used to make every text and images center by default */
   text-align: center;
 }

 .App-logo {
   height: 40vmin;
   pointer-events: none;
 }

 /* Do not remove these code untill you check where it is used */
 /* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

 /* .App-header {
  background-color: #d5f5d2;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #0a20e5;
}

.App-link {
  color: #84e1fd;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
} */
