.FeatureFeedBack_Container {
    display: flex;
    height: 720px;
    padding: 40px;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    border-radius: 8px;
    background: #F0EBFF;
}

.FeatureFeedBack_img {
    display: flex;
    width: 360px;
    padding: 40px 60px 0px 60px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.FeatureFeedBack_headingConatiner {
    display: flex;
    width: 360px;
    padding: 0px 28px 12px 28px;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}

.FeatureFeedBack_headingConatiner h1 {
    color: #434343;
    font-size: 24px;
    font-weight: 500;
    margin: 0;
}

.FeatureFeedBack_headingConatiner h3 {
    color: #434343;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
}

.FeatureFeedBack_Container .ant-input {
    padding: 16px;
    border-radius: 3px;
    border-color: #FFF;

    &::placeholder {
        color: #8C8C8C;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
    }
}

.FeatureFeedBack_Container Button {
    width: 169px;
    height: 36px;
    padding: 4px 15px;
    color: #FFF;
    border: 1px solid #4800FF;
    background: #4800FF;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
}