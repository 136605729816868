.WelcomeNudgeComponent_container {
  display: flex;
  width: 100%;
  padding: 2px 40px 3px 56px;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  background: #fff;
}

.WelcomeNudgeComponent_subContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.WelcomeNudgeComponent_subContainer h1 {
  color: #4800ff;
  font-size: 30px;
  font-weight: 500;
  margin: 0;
}

.WelcomeNudgeComponent_subContainer h4 {
  color: #434343;
  font-size: 20px;
  font-weight: 500;
  text-align: start;
  margin: 0;
}

.SubscribeNudgeComponent_container {
  display: flex;
  width: 24%;
  padding: 18px 0px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: #f0ebff;
  align-self: stretch;
  justify-content: center;
}

.SubscribeNudgeComponent_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.SubscribeNudgeComponent_text h5 {
  color: #4800ff;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
}

.SubscribeNudgeComponent_text p {
  color: #434343;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin: 0;
}

.SubscribeNudgeComponent_container Button {
  border: 1px solid #4800ff;
  background: #4800ff;
  color: #fff;
}

.InsightsNudgeComponent_container{
  display: flex;
  padding: 0px 30px 20px 0px;
  flex-direction: column;
  gap: 12px;
  align-self: stretch;  
}

.InsightsNudgeComponent_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.InsightsNudgeComponent_title h5{
  color:  #4800FF;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; 
  margin: 0;
}

.InsightsNudgeComponent_title a{
  color:  #4800FF;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px; 
  margin: 0;
}

.InsightsNudgeComponent_cardConatiner{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 16px;
}

.InsightsNudgeComponent_card{
  display: flex;
  align-items: flex-start;
  border-radius: 8px;
  background: #FFF;
  min-width: 338px;
  width: 100%;
}

.InsightsNudgeComponent_text {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.InsightsNudgeComponent_text h5{
  text-align: start;
  color:  rgba(0, 0, 0, 0.85);
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 24px;
  line-height: 24px; 
  margin: 0;
  align-self: stretch;
}

.InsightsNudgeComponent_text p{
  color:  #8C8C8C;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin: 0;
}

.ExploreNudgeComponent_container{
  display: flex;
  padding: 0px 30px 20px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.ExploreNudgeComponent_container h5 {
  color:  #4800FF;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; 
  margin: 0;
}

.ExploreNudgeComponent_card {
  display: flex;
  padding: 12px;
  align-items: flex-start;
  background: #fff;
  width: 450px;
  border-radius: 6px;
}

.ExploreNudgeComponent_text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.ExploreNudgeComponent_text h4 {
  color: #434343;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px; 
  margin: 0;
}

.ExploreNudgeComponent_text p {
  color: #434343;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  min-width:202px;
  width: 100%;
  margin: 0;
  text-align: start;
}

.ExploreNudgeComponent_img {
  position: relative;
}

.ExploreNudgeComponent_img .anticon svg {
  height: 30px;
  width: 30px;
  position: absolute;
  left: 106px;
  top: 49px;
  border-radius: 50%;
  color: #595959;
  background: #ffffff;
}

.ExploreNudgeComponent_img img {
  width: 223px;
  height: 126px;
}

.ExploreNudgeComponent_videoModal .ant-modal-content{
  height: 576px;
}

.ExploreNudgeComponent_videoModal {
  width: 976px !important;
  top: 100px !important;
}

.ExploreNudgeComponent_videoModal iframe {
  width: 100%;
  height: 500px;
  position: relative;
  top: 32px;
}

.CP_container {
  padding: 0px 30px 20px 0px;
}

.CP_subContainer {
  border-radius: 6px;
  background: #FFFFFF;
  padding: 75px 24px 75px 24px;
}
