.VendorOnboard_adding-2 {
    display: flex;
    padding: 24px;
    align-items: center;
    gap: 24px;
}

.VendorRank_firstContainer {
    display: flex;
    padding: 34px;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 8px;
    background: #FFF;
}

.VendorRank_infoContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    width: 462px;
}

.VendorRank_infoContainer h1 {
    font-size: 24px;
    font-weight: 500;
    margin: 0;
}

.VendorRank_infoDescription {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}

.VendorRank_infoContainer h4 {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
}

.VendorRank_infoDescription ul {
    padding: 0 7px 0 30px;
}

.VendorRank_infoDescription ul li {
    font-size: 16px;
    font-family: sans-serif;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}