.ProgressBarComponent_container{
    padding: 0px 30px 20px 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .ProgressBarComponent_container h5 {
    color: #4800FF;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
  }
  
  .ProgressBarComponent_subContainer{
    display: flex;
    min-width: 450px;
    width: 100%;
    padding: 20px 30px 22px 30px;
    align-items: center;
    gap: 30px;
    border-radius: 6px;
    background:  #FFF;
  }
  
  .ProgressBarComponent_progressContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    flex-shrink: 0;
    width: 82%;
  }
    
  .ProgressBarComponent_progressContainer p{
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin: 0;
  }
  
  .ProgressBarComponent_content{
    display: flex;
    align-items: flex-start;
    gap: 16px;
    width: 84%;
    min-width: 360px;
  }
  
  .ProgressBarComponent_hr {
    color: #BFBFBF;
    width: 22%; 
    height: 2px; 
    transform: translateY(6px) !important;
  }
  
  .ProgressBarComponent_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 152px;
  }
  
  .ProgressBarComponent_subText{
    display: flex;
    gap: 8px;
  }
  
  .ProgressBarComponent_subText span {
    height: 24px;
    min-width: 24px;
    color: #FFFFFF;
    background-color: #BFBFBF;
    border-radius: 50%;
    display: flex;
    align-items: center; 
    justify-content: center;
    font-size: 12px;
    font-weight: 400;
  }
  
  .ProgressBarComponent_subText .anticon svg {
    height: 24px;
    width: 24px;
    color: #4800FF;
  }
  
  .ProgressBarComponent_text p {
    color:  #8C8C8C;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px; 
    margin: 0;
    padding-left: 32px;
  }
  
  .ProgressBarComponent_subText a {
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px; 
    margin: 0;
  }

  @media (max-width: 1254px) {
    .ProgressBarComponent_subContainer  {
      gap: 24px;
    }
    .ProgressBarComponent_hr{
      width: 10%;
    }
    .ProgressBarComponent_progressContainer{
      width: 77%;
    }
    .ProgressBarComponent_content{
      width: 101%;
    }
  }