.VendorOnboard_adding {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 24px;
    gap: 24px;
    width: 100%;
}

.BulkUpload_container,
.AddManual_container,
.StepByStepAddition_container,
.Registration_container {
    width: 39vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    gap: 24px;
    background: #FFFFFF;
    border-radius: 12px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 1;
}

.StepByStepAddition_container {
    align-items: flex-start;
    padding: 32px;
    gap: 44px;
    align-self: stretch;
}

.Registration_container {
    padding: 0 0 24px;
    border-radius: 0;
    gap: 0;
    width: 100%;
}

.BulkUpload_img {
    display: flex;
    align-items: center;
    gap: 7px;
}

.BulkUpload_seperateImg img {
    padding: 20px;
    border-radius: 16px;
    background: #E4EBF7;
}

.BulkUpload_seperateImg span {
    font-size: 14px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
}

.BulkUpload_sideArrow {
    border-left: 20px solid #E4EBF7;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    position: relative;
    top: -14px;
    left: -7px;
}

.BulkUpload_seperateImg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
}

.BulkUpload_heading h2,
.AddManual_heading h2 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    margin: 0;
}

.AddManual_subheading p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    margin: 0;
}

.BulkUpload_actionsItems {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.AddManual_content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    justify-content: center;
}


.BulkUpload_actionsItems Button,
.AddManual_button Button {
    background: #3A00CC;
}

.BulkUpload_link img {
    padding-left: 10px;
    color: #4800FF;
}

.BulkUpload_link span {
    color: #4800FF;
}

.BulkUpload_link span:hover,
.BulkUpload_link img:hover {
    text-decoration: underline;
}

.BulkUpload_dragArea .ant-upload-drag-container {
    max-width: 516px;
    width: 28vw;
    background: #FAFAFA;
    border-radius: 8px;
    display: flex !important;
    flex-direction: column;
    gap: 20px;
}

.ant-upload-drag-icon .anticon svg {
    font-size: 45.11px;
    width: 45.11px;
    height: 45.51px;
    color: #4800FF;
}

.ant-upload-text p {
    margin: 0;
}

.BulkUpload_or h3 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    margin: 0;
}

.BulkUpload_buttons {
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.BulkUpload_loading {
    position: fixed;
    top: 86px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.45);
    z-index: 9999;
}

.BulkUpload_loading .ant-spin {
    position: relative;
    gap: 24px;
    flex-direction: column;
    display: flex;
    align-items: center;
}

.ant-spin .ant-spin-dot {
    width: auto;
    height: auto;
}

.BulkUpload_loading .anticon svg {
    height: 48px !important;
    width: 48px;
    color: #FFFFFF;
}

.ant-alert-error {
    border: 1px solid #FFE58F;
    background: #FFFBE6;
    max-width: 516px;
    width: 100vw;
    text-align: start;
}

.StepByStepAddition_subContainer {
    display: flex;
    gap: 24px;
}

.StepByStepAddition_details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.StepByStepAddition_icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px;
    gap: 4px;
    width: 52px;
    height: 52px;
    background: #F0EBFF;
    border-radius: 12px;
}

.StepByStepAddition_icon .anticon svg {
    height: 22px;
    width: 23.38px;
    color: #4800FF;
}

.StepByStepAddition_details p {
    margin-top: 8px;
    margin-bottom: 20px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}

.StepByStepAddition_details h2 {
    margin: 0;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
}

.ant-upload-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.Registration_main {
    width: 100%;
}

.Registration_subContainer {
    width: 100%;
}

.Registration_heading h1 {
    margin: 0;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
}

.Registration_save a {
    color: #aba8a8;
}

.Registration_firstBox {
    border-bottom: 1px solid #d7d4d4;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
}

.Registration_secondBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
}

.Registration_title {
    text-align: start;
    padding-bottom: 8px;
}

.Registration_title .vform,
.Registration_input Input::placeholder,
.Registration_input .ant-select::placeholder {
    font-weight: 400;
    font-size: 14px;
}

.Registration_input {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: flex-start;
}

.Registration_input Input,
.Registration_input .ant-select,
.Registration_input .ant-picker {
    border-radius: 0;
    max-width: 390px;
    width: 100%;
}

.Registration_input .ant-input-group-addon {
    border-radius: 0;
}

.Registration_container-gap {
    margin-top: 24px;
}

.Registration_buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    gap: 10px;
}

.Registration_button {
    width: 100%;
}

.Registration_inputField {
    position: relative;
    width: 18vw;
    max-width: 379px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.Registration_inputField .v_red {
    color: #FF4D4F !important;
    margin: 0;
    font-size: 12px;
}
.Registration_inputField .v_yellow {
    color: #FAAD14 !important;
    margin: 0;
    font-size: 12px;
}


.Registration_inputField .vform {
    position: absolute;
    top: 6px;
    left: 6px;
    padding: 0 5px;
    color: #bfbfbf;
    font-size: 12px;
    z-index: 99;
}
.Registration_inputField .vform p {
    color: #bfbfbf !important; 
    margin: 0;
}

.Registration_inputField .ProfileView_subTitleEditing {
    position: absolute;
    padding: 4px;
    z-index: 1;
    width: 96%;
}

.Registration_inputField .ProfileView_subTitleEditing input {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}

.Registration_inputField .ProfileView_subTitleEditing .ant-input {
    font-size: 12px;
    height: 20px;
    padding-left: 6px;
}

.Registration_inputField .ProfileView_subTitleEditing .ant-btn {
    height: 20px;
}

.Registration_inputField .vform .anticon svg{
    height: 12px;
    width: 12px;
    transform: translate(2px,-1px);
}

.point {
    position: absolute;
    z-index: 99;
    padding-left: 4px;

}

.Registration_inputField input,
.Registration_input .ant-picker {
    padding-top: 10px;
    padding-bottom: 10px;
}

.Registration_inputField .ant-select-selector {
    height: 44px !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 0;
}

.Registration_inputField .ant-select-selection-item,
.Registration_inputField .ant-select-selection-placeholder {
    display: flex;
}

.Registration_inputField.hasValue .ant-input {
    padding-top: 20px;
    padding-bottom: 0;
}

.Registration_inputField.hasValue .ant-select-selection-item,
.Registration_inputField.hasValue .ant-picker-input input  {
    position: relative;
    top: 9px;
    line-height: 22px;
}

.Registration_inputField input:focus + .vform,
.Registration_inputField input:not(:placeholder-shown) .vform,
.Registration_inputField input:not(:empty) + .vform {
    top: -12px;
    left: 10px;
    font-size: 12px;
    color: #1890ff;
}

.Toggler_main {
    display: flex;
    padding: 14px 24px;
    align-items: center;
    justify-content: space-between;
    background-color: #FFFFFF;
    margin-bottom: 12px;
}

.Toggler_main span {
    color: #FF4D4F;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px; 
}

.Toggler_containerLeft {
    display: flex;
    align-items: flex-start;
    gap: 40px;
}

.Toggler_containerLeft .ant-switch {
    margin-left: 8px;
}

.Toggler_containerLeft .ant-switch.ant-switch-checked:hover,
.Toggler_containerLeft .ant-switch.ant-switch-checked {
    background-color: #4800FF !important;
}

.Toggler_containerLeft span {
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.Registration_inputField .mandatoryEmpty {
    border: 1px solid #FF4D4F; 
}

.Registration_inputField.mandatoryPending {
    border: 1px solid #FAAD14; 
}

