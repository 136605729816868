.sp_container {
  display: inline-flex;
  padding: 12px 80px 24px 80px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
}

.sp_subContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.sp_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  max-width: 346px;
}

.sp_text h1 {
  color: #262626;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  margin: 0;
}

.sp_text p {
  color: #434343;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
}
