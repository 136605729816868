.VendorLists_filter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 30px;
    width: 100%;
    position: relative;
}

.VendorList_reset {
    min-width: 150px;
    display: flex;
    padding: 9px 4px;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    color: #4800FF;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
}

.VendorLists_search {
    width: 41%;
}

.VendorLists_search .ant-input-group-wrapper {
    max-width: 500px;
    width: 100%;
}

.VendorLists_search .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-radius: 2px;
    border: 1px solid #D9D9D9;
}

.VendorLists_search .ant-input-affix-wrapper {
    height: 40px;
}

.VendorLists_search .ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
    height: 40px;
    border-radius: 0px 2px 2px 0px;
    font-size: 16px;
    border: 1px solid #4800FF;
    background: #4800FF;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
}

.VendorList_filterSelect {
    display: flex;
    align-items: center;
    gap: 8px;
}

.VendorList_filterSelect .ant-select-selector {
    padding: 8px 12px !important;
    height: 40px !important;
    border-radius: 2px;
    width: 160px !important;
}

.VendorList_filterSelect .ant-select-selection-item {
    display: flex;
    align-items: center;
}

.VendorLists_filter .ant-btn {
    height: 40px;
    padding: 0px 16px;
    font-size: 16px;
    border-radius: 0px 2px 2px 0px;
    border: 1px solid #4800FF;
    background: #4800FF;
    color: #FFFFFF;
    display: flex;
    align-items: center;
}

.VendorLists_filter .ant-btn:hover {
    color: #FFFFFF;
}

.VendorLists_filter .ant-btn img {
    padding-left: 4px;
}

.ListComponent_radio .ant-radio-wrapper {
    color: #595959;
}

.ListComponent_radioOption {
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;
}

.ListComponent_radioOption p {
    color: #4800FF;
}

.ListComponent_radio .ant-checkbox-checked .ant-checkbox-inner,
.ListComponent_box1 .ant-checkbox-checked .ant-checkbox-inner,
.ListComponent_radio .ant-checkbox-checked .ant-checkbox-inner:hover,
.ListComponent_box1 .ant-checkbox-checked .ant-checkbox-inner:hover {
    border-color: #4800FF;
    background-color: #4800FF;
}

.filter_icon svg {
    width: 20px;
    height: 20px;
    transform: rotate(-90deg);
}

.ListComponent_sorter {
    display: flex;
    padding: 4px 16px;
    align-items: center;
    justify-content: space-between;
    flex: 1 0 0;
    align-self: stretch;
    position: relative;
}

.ListComponent_sorter p {
    font-size: 14px;
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 22px;
}

.ListComponent_sorterIcon {
    display: flex;
    flex-direction: column;
    color: rgba(0, 0, 0, 0.45);
}

.ListComponent_sorterIcon .anticon svg {
    height: 11px;
    width: 11px;
}

.ListComponent_sorterIcon .anticon-caret-up {
    transform: translateY(1px);
}

.ListComponent_sorterIcon .anticon-caret-down {
    transform: translateY(-2px);
}

.ListComponent_name {
    display: flex;
    align-items: center;
    gap: 4px;
}

.ListComponent_category {
    position: absolute;
    left: 346px;
}

.category-arrow .ant-popover-inner {
    padding: 6px;
}

.ListComponent_categoryData .ant-space-item {
    padding: 5px 12px;
    border-radius: 4px;
}

.ListComponent_categoryData .ant-space-item:hover {
    background: rgba(0, 0, 0, 0.04);
}

.ListComponent_categoryIcon {
    cursor: pointer;
}

.ListComponent_categoryIcon .anticon svg {
    height: 11px;
    width: 11px;
    color: rgba(0, 0, 0, 0.25);
}

.VendorList_filterSelect .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.88) !important;
}

.VendorLists_adding {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 24px;
    width: 100%;
}

.ListComponent_container,
.ProfileView_container {
    width: 100%;
    background-color: white;
}

.VendorLists_adding .ant-list-item {
    padding: 16px 24px;
}

.ListComponent_box1 {
    display: flex;
    align-items: center;
    gap: 16px;
}

.ListComponent_box2.itrrr {
    cursor: pointer;
}

.ListComponent_box2 {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.ListComponent_qt {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
}

.ListComponent_qt span {
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
}

.ListComponent_qs {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.ListComponent_reminder {
    color: #F5222D;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    padding: 1px 8px;
    border-radius: 2px;
    border: 1px solid #FFA39E;
    background: #FFF1F0;
}

.ListComponent_reminder_partial {
    color: #fa8c16;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    padding: 1px 8px;
    border-radius: 2px;
    border: 1px solid #ffd591;
    background: #fff7e6;
}

.ListComponent_qs i {
    height: 6px;
    width: 6px;
    border-radius: 50%;
}

.ListComponent_qa .anticon svg {
    height: 12px;
    width: 12px;
}

.ListComponent_qa {
    display: inline-flex;
    padding: 6px;
    border-radius: 16px;
    background: #F5F5F5;
}

.ListCompoenent_qs1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
}

.pce {
    align-items: center;
    gap: 6px;
}

.pce span {
    display: flex;
    padding: 1px 8px;
    align-items: center;
    gap: 3px;
    border-radius: 2px;
    color: #52C41A;
    border: 1px solid #B7EB8F;
    background: #F6FFED;
}

.pce p,
.plm {
    color: rgba(0, 0, 0, 0.25);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
}

.xdc {
    width: 231px;
    justify-content: flex-end;
}

.xdc span:first-child {
    color: #4800FF;
    cursor: pointer;
}

.xdc span:nth-child(2) {
    color: rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.ListComponent_subCategory {
    position: absolute;
    left: 320px;
    width: 116px;
}

.ListComponent_subCategory2 {
    position: absolute;
    left: 316px;
    width: 116px;
}

.ListComponent_box2 p {
    margin: 0;
}

.ListComponent_box1 .ant-avatar {
    color: #FA541C;
    font-size: 14px;
    min-width: 32px;
    min-height: 32px;
}

.ListComponent_tickPopover {
    z-index: -1;
}

.ListComponent_tickPopover .ant-popover-content {
    left: -12px;
}

.list_popover .ant-popover-content {
    left: 10px;
}

.ListComponent_tick {
    display: flex;
    gap: 8px;
    align-items: flex-start;
    max-width: 212px;
}

.ListComponent_tick .anticon svg {
    height: 22px;
    width: 22px;
    color: #9166FF;
}

.ListComponent_tickDetail {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}

.ListComponent_tickDetail .ant-btn {
    border: 1px solid #4800FF;
    background: #4800FF;
    color: #FFFFFF;
    height: 30px;
    padding: 0 12px;
}

.ListComponent_box1 h3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
}

.ListComponent_box1 p {
    color: rgba(0, 0, 0, 0.45);
    margin: 0;
}

.ListComponent .anticon svg {
    height: 14px;
    width: 14px;
    color: #4800FF;
}

.ListComponent_moreIcon {
    color: rgba(0, 0, 0, 0.45);
}

.ListComponent_moreIcon svg {
    height: 20px;
    width: 20px;
}

.ListComponent_container .ant-pagination {
    padding: 28px;
    background-color: rgb(245, 245, 245);
}

.ListComponent_disabled {
    color: rgba(0, 0, 0, 0.25) !important;
    cursor: not-allowed;
}

/* .ant-typography {
    display: flex;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #F0F0F0;
} */

.Modal_subConatiner {
    width: 100%;
}

.Modal_typography {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
}

.Modal_typography p {
    margin: 0;
    max-width: 870px;
    text-align: initial;
}

.ModalList_Button {
    background: #4800FF;
    color: #FFF;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
}

.Modal_notes {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
}

.Modal_notes h4 {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
}

.Modal_notes p {
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    margin-bottom: 20px;
}

/* .ant-modal {
    width: 100% !important;
} */

.Modal_leftTypography {
    display: flex;
    gap: 16px;
}

.Modal_leftTypography p {
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    font-weight: 400;
    margin: 0;
}

.modal_popup {
    &:before {
        content: '';
        position: absolute;
        left: 80%;
        transform: translateX(-50%);
        top: -15px;
        border: 10px solid transparent;
        border-bottom-color: #FFFFFF;
    }
}


/* ----------------------------PayWall Modal -------------------------------- */

.Paywall_modal {
    max-width: 60vw;
    top: 100px !important;
}

.Pw_layout {
    display: flex;
    padding: 28px 48px;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    border-radius: 8px;
    background: #F0EBFF;
}

.Pw_top {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
}

.Pw_top h3 {
    color: #434343;
    font-size: 30px;
    font-weight: 500;
    line-height: 40px;
    margin: 0;
}

.Pw_bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.Pw_switchGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}

.Pw_switchGroup span {
    color: #434343;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.Pw_switch {
    display: flex;
    padding: 8px 30px;
    align-items: center;
    gap: 16px;
    border-radius: 8px;
    border: 1.5px solid #D9D9D9;
}

.Pw_switch span {
    color: #434343;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.Pw_switch .ant-switch-inner {
    background-color: #B9D2F7;
    box-shadow: inset 0 0 0 0.5px #5491ed;
}

.Pw_switch .ant-switch .ant-switch-handle::before {
    background-color: #3c80ea;
}

.Pw_cardGroup {
    display: flex;
    align-items: center;
    gap: 36px;
}

.Pw_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFF;
}

.Pw_card-1 {
    padding: 16px 24px;
    gap: 30px;
    border-radius: 6px;
    max-width: 430px;
}

.Pw_card-2 {
    padding: 32px 40px;
    gap: 36px;
    border-radius: 6px;
    border: 1.5px solid #4800FF;
    position: relative;
    max-width: 480px;
}

.Pw_cardTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.Pw_cardTop span {
    color: #434343;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
}

.Pw_cardTop-2 {
    display: flex;
    width: 200px;
    flex-direction: column;
    align-items: center;
    gap: 4px;
}

.Pw_cardTop-2 h2 {
    color: #3A00CC;
    font-size: 30px;
    font-weight: 500;
    line-height: 40px;
    margin: 0;
}

.Pw_cardTop-2 span p {
    color: #595959;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
}

.Pw_cardBottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.Pw_cardBottom-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.Pw_cardPoints {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.Pw_cardEachpoint {
    display: flex;
    gap: 8px;
}

.Pw_cardEachpoint p {
    color: #434343;
    font-size: 12px;
    text-align: start;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
}

.Pw_cardEachpoint .anticon {
    transform: translateY(5px);
}

.Pw_cardEachpoint .anticon svg {
    width: 13px;
    height: 13px;
}

.Pw_cardPlan {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    width: 284px;
}

.Pw_cardPlan-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 287px;
}

.Pw_cardPlan span,
.Pw_cardPlan-1 span {
    color: #434343;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.Pw_cardTick {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    width: 100%;
}

.Pw_cardEachTick {
    display: flex;
    align-items: flex-start;
    width: 100%;
    justify-content: space-between;
}

.Pw_topCard {
    display: flex;
    gap: 8px;
    color: #434343;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.Pw_topCard .anticon svg {
    transform: translateY(3px);
}

.Pw_cardEachTick .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #4800FF;
    border-color: #4800FF;
}

.Pw_cardEachTick .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
    border-color: #4800FF;
}

.Pw_cardEachTick .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    background-color: #4800FF;
    border-color: #4800FF;
}

.Pw_cardEachTick p {
    color: #4800FF;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
    display: flex;
    gap: 8px;
}

.Pw_cardEachTick .ant-checkbox+span {
    color: #434343;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.Pw_cardBottom-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.Pw_cardBottom-2 .ant-btn {
    padding: 4px 48px;
    font-size: 16px;
    line-height: 24px;
}

.Pw_basicBtn .ant-btn {
    border: 1px solid #4800FF;
    background-color: #F0EBFF;
    color: #4800FF;
}

.Pw_premiumBtn .ant-btn {
    border: 1px solid #4800FF;
    background-color: #4800FF;
    color: #FFF;
}

.Pw_payIcon {
    display: flex;
    align-items: center;
    gap: 8px;
}



/* remove it after API integration  */


.Pw_price {
    color: #9166FF !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 22px !important;
    text-decoration-line: line-through !important;
}


.Pw_planCardText {
    padding: 0px 8px;
    color: #FFF !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    border-radius: 12px;
    background: #4800FF;
    position: absolute;
    top: -22px;
    right: 28px;
    line-height: 20px;
}

.disabled-Unlockbutton {
    opacity: 0.7;
    cursor: not-allowed;
}

/* ----------------------- Ask Quotes CSS -------------------------------- */

.Quotes_modal {
    max-width: 60vw;
    min-width: 1000px;
}

.Quotes_container {
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
}

.Quotes_modal .ant-modal-content {
    padding: 0 !important;
}

.Quotes_modal .ant-modal-title {
    padding: 16px 24px;
    box-shadow: 0px -1px 0px 0px #f0f0f0 inset;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
}

.Quotes_modal .ant-btn {
    background: #4800FF;
    border: 1px solid #4800ff;
    display: flex;
    align-items: center;
    gap: 2px;
}

.Quotes_modal .ant-btn span {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
}

.Quotes_modal .ant-modal-header {
    margin-bottom: 0 !important;
}

.Quotes_container .ant-form-item {
    width: auto;
    margin: 0;
}

.Quotes_containernt-row .ant-form-item-row {
    display: flex;
    gap: 20px;
}

.Quotes_firstContainer {
    width: 100%;
    color: #595959;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Quotes_firstContainer div {
    display: flex;
    align-items: center;
    gap: 28px;
    color: #595959;
}

.Quotes_firstContainer div div {
    gap: 0;
}

.Quotes_firstContainer .anticon-check {
    border-color: #D9D9D9;
    background-color: #F5F5F5;
    padding: 8px;
}

.Quotes_firstContainer div span span {
    padding-left: 6px;
}

.Quotes_firstContainer div .ant-input {
    width: 280px;
    height: 32px;
    border-radius: 2px;
}

.Quotes_link {
    display: flex !important;
    gap: 12px !important;
}

.Quotes_link .anticon svg {
    color: #4800FF;
}

.ant-switch.ant-switch-checked,
.ant-switch.ant-switch-checked:hover {
    background: #4800FF !important;
}

.Quotes_units .ant-select-selector {
    background: #F5F5F5 !important;
    min-width: 74px;
}

.Quotes_firstContainer .ant-select-in-form-item {
    min-width: 100px;
}


.Quotes_firstContainer .ant-form-item .ant-form-item-control:first-child:not([class^="'ant-col-'"]):not([class*="' ant-col-'"]) {
    display: flex;
    align-items: flex-start;
}

.Quotes_firstContainer .quoteIDCopy .anticon svg {
    height: 20px;
    width: 20px;
    color: #4800FF;
    transform: translateY(2px);
}

.Quotes_secondContainer {
    display: flex;
    flex-direction: column;
    gap: 28px;
    width: 100%;
}

.Quotes_secondContainer p,
.Quotes_secondContainer span {
    font-size: 16px;
    margin: 0;
}

.custom-dropdown .ant-select-item-option[value=""][aria-selected="true"] {
    color: red;
}

.Quotes_secondContainer p:nth-child(1):before {
    display: inline-block;
    margin-inline-end: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
}

.Quotes_secondSubContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
}

.Quotes_secondSubContainer .ant-input,
.Quotes_itemsData .ant-input,
.Quotes_itemsData .ant-input-affix-wrapper,
.Quotes_itemsData .ant-picker {
    border-radius: 3px;
    padding: 8px 12px;
}

.Quotes_itemsData .ant-input-number-input {
    border-radius: 3px;
    padding: 0px 12px;
}

.Quotes_itemsData .ant-input-number {
    border-radius: 3px;
    padding: 4px 0px;
}

.Quotes_typography .ant-input-group.ant-input-group-compact {
    display: flex;
}

.Quotes_items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.Quotes_itemsData {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    width: 100%;
}

.Quotes_itemTitle {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.Quotes_typography {
    display: flex;
    padding: 16px 16px 20px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
}

.Quotes_typography .ant-select-selector {
    border-radius: 0 3px 3px 0 !important;
    min-height: 40px !important;
}

.Quotes_typography span {
    font-size: 14px;
}

.Quotes_typography>div {
    display: flex;
    align-items: flex-end;
    gap: 12px;
}

.Quotes_typography>div>div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
}

.Quotes_typography>div>div .ant-form-item {
    width: 100%;
}

.Quotes_hr {
    color: #595959;
    transform: translateY(-8px) !important;
}

.Quotes_container .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border-radius: 3px;
}

.Quotes_container .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    border-radius: 3px;
}

.Quotes_container .ant-select-multiple .ant-select-selection-overflow {
    width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    padding: 8px 0;
}

.Quotes_container .ant-select-selection-overflow-item {
    transform: translateY(-4px);
}

.Quotes_container .ant-select-selection-overflow-item .anticon svg {
    height: 12px;
    width: 12px;
}

.Quotes_container .ant-select-selection-item {
    border-radius: 20px;
    height: 26px;
    display: flex;
    align-items: center;
}

.Quotes_addNew .anticon svg {
    height: 22px;
    width: 22px;
    transform: translateY(4px);
}

.Quotes_addNew {
    color: #4800FF;
}

.Quotes_secondSubContainer .anticon svg {
    height: 16px;
    width: 16px;
    margin-right: 8px;
}

.Quotes_footer {
    padding: 10px 16px;
    box-shadow: 0px 1px 0px 0px #f0f0f0 inset;
    display: flex;
    justify-content: flex-end;
    /* height: 31px; */
}


/* -------------------------Edit Details Modal------------------------------ */
.EditModal_container {
    max-width: 1152px;
    width: 90% !important;
}

.EditModal_container .ant-modal-content {
    padding: 0 !important;
}

.EditModal_container .ant-modal-title {
    padding: 16px 24px;
    box-shadow: 0px -1px 0px 0px #f0f0f0 inset;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
}

.EditModal_subContainer {
    background-color: rgb(245, 245, 245);
}

.EditModal_container .ant-modal-footer {
    padding: 10px 16px;
    justify-content: flex-end;
    box-shadow: 0px 1px 0px 0px #f0f0f0 inset;
    margin: 0;
}

.EditModal_container .ant-btn {
    border: 1px solid #4800ff;
    background: #4800ff;
    color: #fff;
}

.EditModal_subContainer.mandatory span {
    text-align: right;
}

.EditModal_form {
    padding: 20px;
    background: #fff;
}

.EditModal_subContainer.mandatory p {
    display: block;
    text-align: right;
    color: #FF4D4F;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    margin: 0;
}

.Registration_inputField p {
    text-align: left;
}

/* -------------------------Category Modal------------------------------ */

.Category_modal {
    max-width: 718px;
}

.Category_modal .ant-modal-content,
.Filter_modal .ant-modal-content {
    padding: 0 !important;
}

.Category_modal .ant-modal-title,
.Filter_modal .ant-modal-title {
    padding: 16px 24px;
    box-shadow: 0px -1px 0px 0px #f0f0f0 inset;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
}

.Category_modal .ant-modal-header,
.Filter_modal .ant-modal-header {
    margin-bottom: 0 !important;
}

.Category_modal .ant-modal-footer,
.Filter_modal .ant-modal-footer {
    padding: 10px 16px;
    justify-content: flex-end;
    box-shadow: 0px 1px 0px 0px #f0f0f0 inset;
    margin: 0;
}

.Category_modal .ant-modal-footer .ant-btn,
.Filter_modal .ant-modal-footer .ant-btn {
    border: 1px solid #4800FF;
    background: #4800FF;
}

.CategoryModal_content {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}

.CategoryModal_content .ant-radio-wrapper {
    padding: 8px 20px 8px 16px;
    align-items: center;
    width: 153px;
    border-radius: 24px;
    background: #F5F5F5;
}

.CategoryModal_content .ant-radio-group {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    gap: 8px;
    flex-wrap: wrap;
}


/* -------------------------Filter Modal------------------------------ */

.Filter_modal {
    max-width: 642px;
}

.Filter_content {
    display: flex;
    padding: 24px;
    align-items: flex-start;
    gap: 16px;
}

.Filter_content .ant-select-selector,
.Filter_content .ant-input {
    padding: 8px 12px !important;
    height: 40px !important;
    border-radius: 2px;
    display: flex;
    align-items: center;
}

.Filter_content .ant-select-selection-item {
    width: 159.822px;
    display: flex;
    align-items: center;
}

.Filter_eachContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
}

.Filter_eachContent .ant-select-selection-placeholder {
    width: 160px;
}

@media (max-width: 1530px) {}

.ExportModal_subContainer .ant-select-selection-item {
    width: 159.822px;
    display: flex;
    align-items: center;
    flex: 1;
    font-weight: normal;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ExportModal_subContainer .ant-select-selection-item .ant-tag {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ExportModal_subContainer .ant-select-selector {
    height: 30px;
    overflow: hidden;
    border-radius: 2px !important;
}

.EditModal_subContainer p {
    margin: 0;
    text-align: center;
}

.postQuoteModal_imgContainer {
    padding: 0 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 24px;

    position: relative;
    border-radius: 12px;
    background: #F8F5FF;
    overflow: hidden;
}

.postQuoteModal_imgBg1 {
    width: 135px;
    height: 338.52px;
    position: absolute;
    right: -55px;
    top: -120.353px;
}

.postQuoteModal_imgBg2 {
    width: 135px;
    height: 251.083px;
    position: absolute;
    right: -10px;
    top: 0;
}

.postQuoteModal_imgBg3 {
    width: 135px;
    height: 338.52px;
    position: absolute;
    left: -85px;
    top: -120.353px;
    transform: rotate(15deg);
}

.postQuoteModal_imgBg4 {
    width: 135px;
    height: 251.083px;
    position: absolute;
    left: -65px;
    top: -180px;
    transform: rotate(30deg);
}

.postQuoteModal_imgCover {
    width: 196px;
    height: 196px;
}

.postQuoteModal_imgContainer h4 {
    color: #595959;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.postQuoteModal_imgContainer p {
    color: #595959;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.postQuoteModal_imgContainer a {
    color: #4800FF;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
}

.postQuoteModal_body {
    padding: 24px;
}

.postQuoteModal_body h4 {
    color: rgba(0, 0, 0, 0.88);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
}

.postQuoteModal_body p {
    color: rgba(89, 89, 89, 0.88);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.postQuoteModal_formItem {
    display: flex;
    column-gap: 16px;
    margin: 16px 0;
}

.postQuoteModal_formItem:last-child {
    margin: 0;
}

.postQuoteModal_formItem .ant-checkbox {
    color: rgba(0, 0, 0, 0.85);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.postQuoteModal_label {
    color: #595959;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.postQuoteModal_label span {
    color: red;
}

p.postQuoteModal_sublabel {
    color: rgba(0, 0, 0, 0.45);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.postQuoteModal_footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 24px;
}

.postQuoteModal_footer {
    color: #4800FF;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    padding: 10px 16px;
}

.postQuoteModal_body div.ant-divider {
    margin: 0 !important;
}

.postQuoteModal_modal .ant-modal-content {
    padding: 0 !important;
}